// Required
@use 'sass:map';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800;900&display=swap');
@import './bootstrap-imports';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

:root {
  --danger-dark: #d0010f;
  --secondary-dark: #f6860e;
  --primary-dark: #0044ab;
  --grey-dark: #605e72;
  --light-dark-grey: #c3c0c0;
}

// Optional
@import '~bootstrap/scss/root';
//@import "~bootstrap/scss/code";
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";

@import '~bootstrap/scss/badge';
//@import "~bootstrap/scss/jumbotron";
@import '~bootstrap/scss/alert';
//@import "~bootstrap/scss/progress";
@import '~bootstrap/scss/media';
//@import "~bootstrap/scss/list-group";
@import '~bootstrap/scss/close';
//@import "~bootstrap/scss/toasts";
@import '~bootstrap/scss/modal';
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~react-datepicker/dist/react-datepicker.css';

// Extending react-datepicker
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 2;
}

img {
  max-width: 100%;
}

body {
  font-family: $font-family-base !important;
  min-height: 100vh;
  padding-top: 132px;

  @include media-breakpoint-only(lg) {
    padding-top: 167px;
  }

  @include media-breakpoint-only(md) {
    padding-top: 154px;
  }

  @include media-breakpoint-only(sm) {
    padding-top: 102px;
  }

  @include media-breakpoint-only(xs) {
    padding-top: 102px;
  }
}

@font-face {
  font-family: 'CorradineHandwriting';
  src:
    url('../public/fonts/CorradineHandwriting.woff2') format('woff2'),
    url('../public/fonts/CorradineHandwriting.woff') format('woff');
}

#__next {
  min-height: calc(100vh - 146px);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.main-layout {
  flex: 1 1 auto;

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}

.table {
  td {
    vertical-align: middle;
  }
}

.slick-slider {
  .slick-slide {
    display: flex !important;
    flex-shrink: 0;
    flex-direction: column;
    height: auto; // ← that must not be ignored
    > div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    > div > div {
      flex-grow: 1;
      outline: none;
    }
  }

  &:not(.slick-vertical) {
    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
    }
  }
}

.border {
  border-width: 3px !important;
}

.slick-dots {
  bottom: 15px;
}

.text-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.text-shadow {
  text-shadow: 0 0 5px #2e3339;
}

.card {
  border: none;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.15);

  &-header {
    background: none;
    border: none;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
}

.text-button-link {
  text-decoration: underline;
}

.btn-squared {
  border-radius: 4px;
}

.btn-semi-squared {
  border-radius: 14px;
}

.btn-outline-primary {
  background: white;
}

.btn-shadow {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}

.btn-full-rounded {
  border-radius: 50%;
}

.card-rounded {
  border-radius: 1rem !important;
}

.heading {
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 0;
  margin-bottom: map.get($spacers, 5);

  &-center {
    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 6px;
    width: 50%;
    background: var(--grey-dark);
    margin-top: map.get($spacers, 4);
  }

  &-small {
    &::after {
      margin-top: 1rem;
      height: 5px;
    }
  }

  &-full::after {
    width: 100%;
  }

  &-full-line::after {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: map.get($spacers, 4);
  }

  @each $key, $val in $theme-colors {
    &-#{$key} {
      &::after {
        background: $val;
      }
    }
  }
}

h4 {
  font-weight: bold;
}

input.form-control.form-control-squared {
  border-radius: 0;
  border: 2px solid #111;
  background-color: none;
}

.form-control {
  &-bordered {
    border: 2px solid $primary;
    background: white;
  }
}

.primary-dark-color {
  color: var(--primary);
}

.middle-blue-color {
  color: var(--middle-blue);
}

button.middle-blue-color:hover,
a.middle-blue-color:hover {
  color: var(--primary);
}

button.dark-grey-color:hover,
a.dark-grey-color:hover {
  color: var(--primary);
}

.text-smaller {
  font-size: 95%;
}

.form-group-heading {
  color: var(--primary);
  font: normal normal 900 18px/19px $font-family-form;
  margin-bottom: 24px;
}

.form-group {
  a {
    text-decoration-line: underline;
  }
}

h1 {
  font-weight: 900;
  margin-top: $spacer * 2;

  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
  }
}

h2 {
  font-weight: 900;

  @include media-breakpoint-down(lg) {
    font-size: $h2-font-size * 0.78;
  }
}

h3,
h4,
h5 {
  font-weight: 800;
}

h1,
h2,
h3,
h4,
h5,
p {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

select.form-control {
  background: #f4f4f4 url('../assets/imgs/downArrow.svg') no-repeat;
  background-position-x: 94%;
  background-position-y: 19px;
  -webkit-appearance: none;
}

label {
  color: var(--primary);
  font-size: 1rem;
  font-weight: 600;
  font-family: $font-family-form;
}

.form-label {
  color: $primary;
  font-size: 1rem;
  font-weight: 700;
  font-family: $font-family-form;
}

.row-no-padding {
  @include media-breakpoint-down(md) {
    [class*='col-'] {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
}

.horizontal-right-image-campaign,
.horizontal-left-image-campaign {
  background-color: var(--light-blue);
}

.horizontal-left-image-campaign,
.horizontal-left-image-campaign h3 {
  font-size: 1.5rem;
  font-weight: normal;
}

.horizontal-right-image-campaign h2,
.horizontal-left-image-campaign h2 {
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.quote-text-container {
  padding: $spacer * 3;
  position: relative;
}

.campaign-homepage-heading {
  color: $black;
}

.full-width-margin-top {
  margin-top: 6rem;
}

.bg-white-70 {
  background: rgba(255, 255, 255, 0.7);
}

.bg-primary-70 {
  background: rgba($primary, 0.7);
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}

.full-width-title-container {
  h1 {
    font-size: 2rem;
    font-weight: 900;
  }

  @include media-breakpoint-down(md) {
    h1 {
      font-size: 1.7rem;
      font-weight: 700;
    }
  }
}

.max-w-750 {
  max-width: 750px;
}

.max-w-450 {
  max-width: 450px;
}

@include media-breakpoint-down(sm) {
  p {
    font-size: 14px;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.no-pointer-events {
  pointer-events: none;
  touch-action: none;
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &-sm {
    width: 30px;
    height: 30px;
  }

  &-md {
    width: 100px;
    height: 100px;
  }

  &-lg {
    width: 170px;
    height: 170px;
  }

  &-xl {
    width: 360px;
    height: 360px;
  }

  &-xxl {
    width: 468px;
    height: 468px;
  }
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.form-check-input {
  height: 20px;
  width: 20px;
}

.text-medium {
  font-weight: 600;
}

.text-normal {
  font-weight: normal !important;
}

.font-secondary {
  font-family: Arial, sans-serif !important;
}

.border-width-5 {
  border-width: 5px;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner,
#nprogress .spinner-icon {
  display: none;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.background-cover {
  background-size: cover;
}

.background-center {
  background-position: center;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-scale-down {
  object-fit: scale-down;
}

.mx-n15px {
  margin: 0 15px !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .border-radius#{$infix} {
      border-radius: $card-border-radius !important;
    }
    .border-radius#{$infix}-none {
      border-radius: 0 !important;
    }
  }
}

.list-style-type-none {
  list-style-type: none;
}

.min-w-auto {
  min-width: auto;
}

.max-w-100 {
  max-width: 100%;
}

.white-space-nowrap {
  white-space: nowrap;
}

.max-vh-50 {
  max-height: 50vh;
}

.max-h-60 {
  max-height: 60%;
}

.max-h-100px {
  max-height: 100px;
}

.shadow {
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-inherit {
  color: inherit;
}

.modal-backdrop.show {
  opacity: 0.75;
}

.rounded-sm {
  border-radius: 10px;
}

.rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-sm-md {
  @include media-breakpoint-up(sm) {
    border-radius: 1.25rem !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker {
  display: flex;
}

.square-amount-input {
  border: 2px solid #000;
  border-radius: 0;
  background-color: #fff;
  margin-left: 0;
}
